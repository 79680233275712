import { all, fork, put, takeEvery } from 'redux-saga/effects'
import actions from './actions'

export function* onChangeLanguage() {
  yield takeEvery(actions.APP_CHANGE_LANGUAGE, function*(action) {
    yield put({
      type: actions.SET_LANGUAGE,
      payload: {
        lang: action.payload,
      },
    })
  })
}

export default function* rootSaga() {
  yield all([fork(onChangeLanguage)])
}
