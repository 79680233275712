// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("C:\\Users\\jatur\\Desktop\\Programing Project\\AAppoint\\aappoint-static\\node_modules\\gatsby-plugin-offline\\app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-index-js": () => import("C:\\Users\\jatur\\Desktop\\Programing Project\\AAppoint\\aappoint-static\\src\\pages\\index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-404-js": () => import("C:\\Users\\jatur\\Desktop\\Programing Project\\AAppoint\\aappoint-static\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-en-faq-create-coupon-js": () => import("C:\\Users\\jatur\\Desktop\\Programing Project\\AAppoint\\aappoint-static\\src\\pages\\en\\faq\\create-coupon.js" /* webpackChunkName: "component---src-pages-en-faq-create-coupon-js" */),
  "component---src-pages-en-privacy-policy-js": () => import("C:\\Users\\jatur\\Desktop\\Programing Project\\AAppoint\\aappoint-static\\src\\pages\\en\\privacy-policy.js" /* webpackChunkName: "component---src-pages-en-privacy-policy-js" */),
  "component---src-pages-th-faq-create-coupon-js": () => import("C:\\Users\\jatur\\Desktop\\Programing Project\\AAppoint\\aappoint-static\\src\\pages\\th\\faq\\create-coupon.js" /* webpackChunkName: "component---src-pages-th-faq-create-coupon-js" */),
  "component---src-pages-th-privacy-policy-js": () => import("C:\\Users\\jatur\\Desktop\\Programing Project\\AAppoint\\aappoint-static\\src\\pages\\th\\privacy-policy.js" /* webpackChunkName: "component---src-pages-th-privacy-policy-js" */),
  "component---src-pages-th-privacy-js": () => import("C:\\Users\\jatur\\Desktop\\Programing Project\\AAppoint\\aappoint-static\\src\\pages\\th\\privacy.js" /* webpackChunkName: "component---src-pages-th-privacy-js" */),
  "component---src-pages-th-term-of-use-js": () => import("C:\\Users\\jatur\\Desktop\\Programing Project\\AAppoint\\aappoint-static\\src\\pages\\th\\term-of-use.js" /* webpackChunkName: "component---src-pages-th-term-of-use-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "C:\\Users\\jatur\\Desktop\\Programing Project\\AAppoint\\aappoint-static\\.cache\\data.json")

