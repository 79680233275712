module.exports = [{
      plugin: require('C:/Users/jatur/Desktop/Programing Project/AAppoint/aappoint-static/node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('C:/Users/jatur/Desktop/Programing Project/AAppoint/aappoint-static/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('C:/Users/jatur/Desktop/Programing Project/AAppoint/aappoint-static/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
