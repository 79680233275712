export default {
    LANG: 'English',
    //Header Section
    FEATURE: 'ฟีเจอร์',
    PRICING: 'ราคา',
    SOLUTION: 'โซลูชั่น',
    ABOUT_US: 'เกี่ยวกับเรา',
    REQUEST_DEMO: 'สาธิตการใช้งาน',
    SHOP_LOGIN: 'ร้านค้าลงชื่อใช้งาน',
    //Footer Section
    DOWNLOAD_NOW_L1: 'ดาวน์โหลดได้แล้ววันนี้',
    DOWNLOAD_NOW_L2: 'ทั้งโทรศัพท์มือถือระบบ iOS และ Android',
    PRODUCT: 'ผลิตภัณฑ์',
    AESTHETIC: 'คลินิกเสริมความงาม',
    MEDICAL: 'คลินิกเวชกรรม',
    DENTAL: 'คลินิกทันตกรรม',
    MASSAGE: 'ธุรกิจบริการนวดผ่อนคลาย หรือ สปา',
    CARWASH: 'ธุรกิจบริการล้างรถ',
    GARAGE: 'ธุรกิจซ่อมรถ หรือ อู่รถ',
    PET: 'ธุรกิจเกี่ยวกับสัตว์เลี้ยง',
    BEAUTY: 'ธุรกิจเสริมความงาม หรือ ตกแต่งผม',
    JOIN_US: 'ช่องทางอื่นๆ ของเรา',
    COPYRIGHT: 'Copyright',
    AAPPOINT: 'AAppoint',
    CONTACT_US: 'ติดต่อเรา',
    TERM: 'ข้อกำหนดการใช้งาน',
    PRIVACY: 'นโยบายความเป็นส่วนตัว',
    FAQ: 'คำถามที่พบบ่อย',
    //HOME Section
    APP_DESCRIPTION_L1: 'ระบบจัดการนัดหมาย',
    APP_DESCRIPTION_L2: 'ที่ช่วยเพิ่มลูกค้า เพิ่มกำไร และลดต้นทุน',
    TRY_FREE: 'ทดลองฟรี (14 วัน)',
    NO_CREDIT_REQUIRE: 'ไม่ต้องใช้บัตรเครดิต',
    AAPPOINT_MARKET_L1: 'ทำความรู้จักกับ',
    MARKET_DETAIL_L1: 'ศูนย์รวมร้านค้าบริการที่ดีที่สุด',
    MARKET_DETAIL_L2: 'ระบบนัดหมาย ที่ทำให้สร้างลูกค้าประจำได้',
    MARKET_DETAIL_L3: 'สามารถสื่อสารกับลูกค้า และเพิ่มการขาย',
    MARKET_DETAIL_L4: 'ได้อย่างง่ายดาย',
    WHAT_IS_AAPPOINT: 'แอพพ้อยท์ คืออะไร ?',
    AAPPOINT_IS: 'แพลตฟอร์มอันดับหนึ่ง สำหรับบริหารการนัดหมาย ระหว่างร้านค้าและลูกค้า โดยสามารถจัดการนัดหมายผ่านระบบออนไลน์ ได้ทุกที่ทุกเวลา พร้อมทั้งมีระบบแจ้งเตือนล่วงหน้าเมื่อใกล้ถึงวันนัดหมาย',
    SEE_MORE: 'ดูทั้งหมด >>',
    WHY_AAPPOINT: 'ทำไมต้องใช้แอพพ้อยท์ ?',
    WHY_AAPPOINT1: 'ดึงลูกค้าเก่ากลับมาใช้บริการโดยการส่งข้อความแจ้งเตือนเช่น แจ้งเตือนให้ลูกค้ากลับมารับบริการตรวจฟันหลังจากครบรอบ 6 เดือน',
    WHY_AAPPOINT2: 'เป็นการทำโฆษณาโดยโฟกัสไปที่กลุ่มเป้าหมายราคาถูกกว่า เพราะไม่ได้ขึ้นอยู่กับจำนวน Message',
    WHY_AAPPOINT3: 'ลูกค้าสามารถเห็นบริการของร้าน แม้ยังไม่เคยใช้บริการ',
    WHY_AAPPOINT4: 'กระแสเงินสดดีขึ้น',
    WHY_AAPPOINT5: 'ยิ่งเพิ่มลูกค้า ยิ่งมีคนเห็นมากขึ้น (Rating)',
    WHY_AAPPOINT6: 'สามารถมองวางแผนการทำงานได้ เพราะมองเห็นนัดหมายของทั้งร้าน',
    WHY_NOW: 'ระบบบริหารธุรกิจ ดิจิทัล ?',
    GET_START: 'เริ่มใช้งาน ฟรี! (14 วัน)',
    TRY_NOW: 'สมัครเลย',
    //FEATURE Section
    MAKE_APPOINT: 'การสร้างนัดหมาย',
    MAKE_APPOINT_DETAIL: 'การสร้างนัดหมาย สร้างนัดหมายง่ายๆด้วยปฏิทินแบบออนไลน์ ที่สามารถเลือกวัน เวลา และมองเห็นข้อมูลนัดหมายพร้อมกันทั้งร้านค้าและลูกค้าเพื่อให้การนัดหมายมีประสิทธิภาพและ ง่ายขึ้นกว่าเดิม',
    AGENDA: 'กำหนดการ และ รายละเอียดนัดหมาย',
    AGENDA_DETAIL: 'ลูกค้า และ ผู้ให้บริการสามารถดูรายละเอียดการนัดหมายได้ ทุกที่ ทุกเวลาผ่านแอปพลิเคชันบนมือถือ และนัดหมายของลูกค้า ร้านค้า รวมถึง หมอ หรือผู้ให้บริการจะถูกเชื่อมโยงกัน',
    NOTI: 'ระบบแจ้งเตือนนัดหมาย (การแจ้งเตือนผ่านแอปพลิเคชั่น)',
    NOTI_DETAIL: 'ระบบช่วยเตือนนัดหมายสำหรับลูกค้า และผู้ให้บริการ ผ่านแอปพลิเคชันบนมือถือโดยแจ้งเตือนนัดหมายก่อนถึงวันนัด และสามารถเช็ควันนัดหมายได้ ทำให้ลูกค้าและผู้ให้บริการ ไม่พลาดการนัดหมายอีกต่อไป',
    RESCHEDULE: 'ระบบเลื่อนนัดหมาย',
    RESCHEDULE_DETAIL: 'ลูกค้าสามารถเลื่อนนัดหมายด้วยตัวเอง ง่าย ๆ ผ่านแอปพลิเคชันบนมือถือทำให้ผู้ให้บริการ และเจ้าของธุรกิจ จัดการเวลานัดหมายให้มีประสิทธิภาพมากกว่าเดิม',
    NOTI_SMS: 'ระบบแจ้งเตือนนัดหมาย (การแจ้งเตือนผ่าน SMS)',
    NOTI_SMS_DETAIL: 'ระบบช่วยเตือนนัดหมายสำหรับลูกค้า และผู้ให้บริการผ่านข้อความ SMS โดยแจ้งเตือนนัดหมายก่อนถึงวันนัดและสามารถเช็ควันนัดหมายได้ ทำให้ลูกค้าและผู้ให้บริการ ไม่พลาดการนัดหมายอีกต่อไป',
    ROOM_MANAGEMENT: 'ระบบจัดการห้องบริการ',
    ROOM_MANAGEMENT_DETAIL: 'สร้างห้องสำหรับการรักษาเพื่อบริหารจัดการ การบริการให้เหมาะสมกับนัดหมายของลูกค้า ทำให้ลูกค้าไม่ต้องรอนาน',
    LEAVE: 'ระบบจัดการเวลาทำงาน',
    LEAVE_DETAIL: 'หมอ หรือผู้ให้บริการ สามารถลงเวลาการทำงานของตนเองง่ายๆผ่านแอปพลิเคชันบนมือถือ เพื่อให้ลูกค้า และ คลินิก หรือ ร้านค้าสามารถจัดการนัดหมายได้ง่ายขึ้น',
    // **delete
    QUEUE: 'ระบบบริหารจัดการคิว',
    QUEUE_DETAIL: 'ระบบจัดการคิว เพื่อให้ลูกค้าไม่ต้องมานั่งรอ สามารถแสดงสถานะคิวปัจจุบันช่วยลดความเสี่ยง และเว้นระยะห่างทางสังคม เพื่อความปลอดภัยของลูกค้าและ ผู้ให้บริการ',
    // delete
    RATING: 'ระบบให้คะแนนความพึงพอใจ',
    RATING_DETAIL: 'ระบบให้คะแนนความพึงพอใจจากการให้บริการ  มีการการันตีคุณภาพยิ่งเพิ่มลูกค้าให้เข้ามาใช้บริการมากเท่าไหร่ ยิ่งมีคนเห็นร้านค้ามากขึ้น',
    // **delete
    SHOP: 'การจัดการร้านค้า',
    SHOP_DETAIL: 'เจ้าของธุรกิจสามารถตั้งค่า เวลาเปิด-ปิด  และการบริการที่ต้องการรวมถึงกำหนดพนักงาน สะดวกมากยิ่งขึ้น และยังลดความซ้ำซ้อนของการทำงาน',
    // delete
    BOOKING: 'ระบบจองบริการ',
    BOOKING_DETAIL: 'ลูกค้าสามารถจองบริการผ่านแอปพลิเคชั่นบนมือถือด้วยตนเอง และเลือกวัน - เวลาที่ต้องการทำนัดหมายกับร้านค้า/คลินิกได้ง่ายๆ  ทำให้ร้านค้าสามารถวางแผนตารางงานได้ เพื่อเพิ่มโอกาสในการสร้างรายได้ให้กับเจ้าของร้านมากยิ่งขึ้น',
    TRAVELTIME: 'เช็คเวลาในการเดิินทาง',
    TRAVELTIME_DETAIL: 'สามารถเช็คเส้นทางและระยะเวลาที่จะไปยังจุดหมาย เพื่อค้นหาเส้นทางที่ดีที่สุดและประหยัดเวลาในการเดินทาง ทำให้ลูกค้าไม่พลาดการนัดหมายของคุณ',
    // **delete
    APPOINT_SUM: 'รานงานสรุปการนัดหมาย',
    APPOINT_SUM_DETAIL: 'ดูรายงานสรุปง่าย สะดวก ได้ทุกที่ ทุกเวลา ง่ายต่อการใช้งานและ ยังช่วยเพิ่มรายได้ให้ธุรกิจของคุณนอกจากนั้นยังทำให้คุณบริหารธุรกิจได้อย่างมีประสิทธิภาพมากขึ้น',
    // delete
    OVERVIEW_FEATURE: 'คุณสมบัติของ แอพพ้อยท์ ที่ช่วยจัดการนัดหมายให้ง่ายยิ่งขึ้น...',
    OVERVIEW_FEATURE_1: 'คุณสมบัติของ แอพพ้อยท์',
    OVERVIEW_FEATURE_2: 'ที่ช่วยจัดการนัดหมายให้ง่ายยิ่งขึ้น...',
    // Request Demo section
    CONTACT_SALE_1: 'ติดต่อฝ่ายขาย',
    CONTACT_SALE_2: 'สอบถามเพิ่มเติม หรือ ต้องการทดลองใช้งาน',
    FIRST_NAME: 'ชื่อ',
    LAST_NAME: 'นามสกุล',
    PHONE_NUM: 'เบอร์โทรศัพท์',
    E_MAIL: 'อีเมล',
    BUSINESS_NAME: 'ชื่อธุรกิจ/ร้านค้า',
    PROVIDE_SERVICE: 'ประเภทธุรกิจ ?',
    SUBMIT_REQUEST: "ส่งข้อความ",
    GREETING_1: 'ขอบคุณที่สนใจผลิตภัณฑ์ของเรา',
    GREETING_2: 'คำขอเพื่อรับการสาธิตการใช้งานของคุณได้ส่งไปยังทีมงานแล้วกรุณารอการติดต่อกลับจากเรา',
    // Pricing section
    // **delete
    PAY_AS_YOU_GROW_1: 'จ่ายตามจริงเท่าที่ใช้',
    PAY_AS_YOU_GROW_2: 'คุณจะจ่ายเงินให้เราก็ต่อเมื่อคุณได้รับเงิน',
    // delete
    SUBSCRIPTION_1: 'ค่าบริการรายเดือน',
    SUBSCRIPTION_2: 'เหมาจ่ายเพียง 1200฿/ร้านค้า',
    NO_INSTALL_FEE: 'ไม่มีค่าติดตั้ง',
    NO_MAINTAIN_FEE: 'ไม่มีค่าบำรุงรักษา',
    UNLIMIT_AAPPOINT: 'ไม่จำกัดการนัดหมาย',
    PRICING_PLAN: 'ค่าบริการสบายกระเป๋า',
    PRICE: 'เหมาจ่ายเพียงเดือนละ 1200฿/ร้านค้า',
    LIMIT: '(สูงสุด 1,000 นัดหมาย/เดือน)',
    // **delete
    TRANSAC_DETAIL_1: 'ฟรี !',
    TRANSAC_DETAIL_2: 'ค่าแรกเข้า จำนวน 2,999 บาท',
    TRANSAC_DETAIL_3: '( เฉพาะ 100 คลินิกแรกเท่านั้น )',
    TRANSAC_DETAIL_4: 'สมัครใช้งานวันนี้ใช้ฟรี 3 เดือน',
    TRANSAC_DETAIL_5: 'ปกติ Transaction ละ 3 บาท',
    // delete
    FEATURE_1: 'การสร้างนัดหมาย',
    FEATURE_2: 'ระบบแจ้งเตือนนัดหมาย',
    FEATURE_2_1: '(การแจ้งเตือนผ่าน SMS)',
    FEATURE_3: 'ระบบให้คะแนนความพึงพอใจ',
    FEATURE_4: 'ดูกำหนดการ และ รายละเอียดนัดหมาย',
    FEATURE_5: 'ระบบเลื่อนนัดหมาย',
    FEATURE_6: 'ระบบจองบริการ',
    FEATURE_7: 'ระบบแจ้งเตือนนัดหมาย',
    FEATURE_7_1: '(การแจ้งเตือนผ่านแอปพลิเคชั่น)',
    FEATURE_8: 'ระบบจัดเวลาทำงาน',
    FEATURE_9: 'เช็คเวลาในการเดินทาง',
    PRICE_TERM: 'เงื่อนไขและรายละเอียดเป็นไปตามที่บริษัทฯ กำหนด | บริษัทฯขอสงวนสิทธิ์ในการเปลี่ยนแปลงโดยไม่แจ้งให้ทราบล่วงหน้า',
    PRICE_TERM_MOBILE_1: 'ราคาไม่รวม Vat 7%',
    PRICE_TERM_MOBILE_2: 'เงื่อนไขและรายละเอียดเป็นไปตามที่บริษัทฯ กำหนด',
    PRICE_TERM_MOBILE_3: 'บริษัทฯขอสงวนสิทธิ์ในการเปลี่ยนแปลงโดยไม่แจ้งให้ทราบล่วงหน้า',
    // Solution section
    SOLUTION_DETAIL_1: 'แอพพ้อยท์ ช่วยให้...',
    SOLUTION_DETAIL_2: 'เราทำมากกว่าแค่การนัดหมาย คุณสามารถบริหารจัดการเวลาให้เหมาะสมกับธุรกิจของคุณได้ ทุกที่ ทุกเวลา และยกระดับการบริการเพื่อทำให้ลูกค้าประทับใจ รวมถึงทำให้ลูกค้าของคุณเป็นคนพิเศษกว่าใคร',
    AESTHETIC_DETAIL: 'แอพพ้อยท์ แพลตฟอร์ม จะทำให้เจ้าของธุรกิจสามารถสร้างลูกค้ารายใหม่ และรักษาลูกค้าเก่า ช่วยจัดเก็บฐานข้อมูลของลูกค้าที่มีทั้งหมด ทำให้ค้นหาข้อมูลได้ง่าย สะดวกมากยิ่งขึ้น นอกจากนี้ยังเป็นจุดเริ่มพื่อเข้าถึงแคมเปญต่าง ๆ ที่ต้องการส่งเสริมการตลาด เพื่อยกระดับ และ สร้างความแตกต่างจากคู่แข่งในธุรกิจ',
    MEDICAL_DETAIL: 'ผลกำไรที่มากขึ้นโดยใช้เวลาน้อยลง ด้วย แอพพ้อยท์ แพลตฟอร์ม ที่จะทำให้เจ้าของธุรกิจมีเวลาไปสร้างรายได้เป็นกอบเป็นกำ แทนที่จะต้องจดจ่อกับการทำจองคิวนัดหมายด้วยตัวเอง อีกทั้งยังได้ใช้ทรัพยากรที่มีอยู่ให้เกิดประโยชน์สูงสุด โดยไม่ต้องมัวแต่จัดการตารางนัดหมายแบบเดิมๆ  แอพพ้อยท์ แพลตฟอร์ม จะเป็นตัวช่วยที่ทำให้คุณและลูกค้าของคุณมีความสุขกับการได้รับบริการที่ประทับใจมากกว่าที่เคย',
    DENTAL_DETAIL: 'หากคุณเคยทำงานโดยใช้กระดาษในการทำนัดหมาย คุณจะรู้ว่าระบบการทำนัดหมายนั้นกลายเป็นสิ่งที่ทำให้เกิดความยุ่งยาก การทำนัดหมายแบบเดิม ๆ เป็นเรื่องที่เสียเวลา เสียเงิน และทรัพยากรที่ต้องใช้ แต่แอพพ้อยท์ แพลตฟอร์ม สามารถแก้ไขปัญหาเหล่านี้ แล้วยังช่วยให้คุณมุ่งเน้นไปที่สิ่งสำคัญกว่านั่นคือ “การมีปฏิสัมพันธ์ที่ดีกับลูกค้า”',
    MASSAGE_DETAIL: 'ธุรกิจบริการไม่สามารถสต๊อกสินค้าเหมือนธุรกิจทั่วไปได้ ดังนั้นการจัดการเรื่อง Demand กับ Supply ให้พอดีกัน เป็นเรื่องที่ยากมาก เช่น บางช่วงเวลาจะมีลูกค้ามาใช้บริการมาก แต่พนักงานไม่เพียงพอ ส่งผลให้สูญเสียลูกค้า แต่ถ้าเพิ่มจำนวนพนักงาน อาจเกิดปัญหาการสิ้นเปลืองต้นทุนค่าใช้จ่าย แต่ แอพพ้อยท์ แพลตฟอร์ม ที่สามารถช่วยให้เจ้าของธุรกิจบริหารเวลาที่ลูกค้าต้องการ ให้เพียงพอกับพนักงาน เพื่อลดต้นทุน เพิ่มรายได้ให้ธุรกิจของคุณ',
    GARAGE_DETAIL: 'แอพพ้อยท์ แพลตฟอร์ม สามารถจัดการตารางเวลาพนักงานของธุรกิจคุณ ทำให้การจัดตารางเวลายืดหยุ่น เพื่อให้พนักงานของคุณมีความสุข และสามารถเต็มที่กับงาน จะส่งผลให้การบริการและงานที่ส่งต่อไปยังลูกค้ามประสิทธิภาพมากขึ้น และยังทำให้ลูกค้าประทับใจกับการบริการที่ดีเยี่ยมได้ดังนั้น แอพพ้อยท์ แพลตฟอร์ม เป็นตัวช่วยที่ดีในการวางแผนตารางเวลา แอพพ้อยท์ แพลตฟอร์ม สามารถจัดการนัดหมายแบบออนไลน์ เพื่อให้คุณสามารถมุ่งเน้นไปที่การบริหารธุรกิจ',
    CARWASH_DETAIL: 'ไม่ว่าคุณจะอยู่ในธุรกิจประเภทใด มักมีลูกค้าที่ ลืม หรือ ยกเลิกนัดหมายเป็นประจำหรือ ต้องการนัดทันที ทำให้พนักงาน หรือ อุปกรณ์ที่คุณเตรียมไว้ไม่เพียงพอแต่สิ่งเหล่านี้สามารถแก้ไขได้ด้วย แอพพ้อยท์ แพลตฟอร์ม ที่จะช่วยให้ธุรกิจสามารถวางแผน และ เตรียมพนักงาน รวมถึงอุปกรณ์ให้เพียงพอได้ล่วงหน้าและเหมาะสม กับลูกค้าที่ต้องการรับบริการได้อย่างมีประสิทธิภาพมากกว่าเดิม',
    PET_DETAIL: 'เจ้าของธุรกิจที่ประสบความสำเร็จส่วนใหญ่มักมองเห็นถึงศักยภาพของลูกค้าเพื่อเติมเต็มความต้องการและสิ่งที่พวกเขายินดีจ่าย เมื่อเจ้าของธุรกิจได้คิดและ วางแผนล่วงหน้าเกี่ยวกับ การบริการประเภทใดที่เหมาะสมกับสัตว์เลี้ยงแต่ละชนิด ก็จะสามารถช่วยจัดการเวลาให้เหมาะสมกับการบริการนั้น ๆ เพื่อทำให้บริการออกมาดี และสนองความต้องการของลูกค้าได้ตรงจุด',
    BEAUTY_DETAIL: 'คุณเคยเจอปัญหาเหล่านี้หรือไม่ ลูกค้าพร้อมใจกันขอนัดหมายในช่วงเวลาเดียวกันหรือ มาใช้บริการพร้อมกันหลาย ๆ คน ทำให้นัดหมายซ้ำซ้อน หรือ นัดคิวลูกค้าผิดปัญหานี้จะหมดไป ด้วยแอพพ้อยท์ แพลตฟอร์ม ที่จะเปลี่ยนเรื่องนัดหมายให้เป็นเรื่องง่าย เเละสะดวกยิ่งขึ้น อีกทั้งยังมีความถูกต้องแม่นยำ โดยสามารถเลือกวันเวลาที่เหมาะสมกับลูกค้าที่สุด นอกจากนี้ยังมีระบบเเจ้งเตือนก่อนถึงวันนัดอีกด้วย',
    // About us section
    ABOUT_US_DETAIL: 'เชื่อมต่อกับ แอพพ้อยท์ ได้ทั่วโลก',
    OUR_TEAM: 'ทีมงานของเรา',
    RATAPON: 'รัฐพล นาควิเชียร',
    YUWANUCH: 'ทพญ.ยุวนุช นาควิเชียร',
    SUWADEE: 'ดร.สุวดี พันธุ์พานิช',
    JATURONG: 'จาตุรงค์ อนุเวชศิริเกียรติ',
    VARISSARA: 'วริศรา บุญเทียม',
    THAMONWAN: 'ธมนวรรณ จันทร์สา',
    UBONRAT: 'อุบลรัตน์ พลชัย',
    KIATTIPHON: 'เกียรติพร สารธิมา',
    POWERED: 'จัดทำโดย',
    ADDRESS: 'ที่อยู่ :',
    TEL: 'โทรศัพท์ :',
    EMAIL: 'อีเมล :',
    LINE_OA: 'ไลน์ :',
    CORP_NAME: 'บริษัท แอพพ้อยท์เม้นท์ เอนี่แวร์ จำกัด (สำนักงานใหญ่)',
    OFFICE_ADDR_1: 'เลขที่ 25 อาคารอัลม่า ลิงค์ ชั้น 17 ห้อง 222',
    OFFICE_ADDR_2: 'ซอยชิดลม ถนนเพลินจิต แขวงลุมพินี เขตปทุมวัน กรุงเทพฯ 10330',
    CONTACT_NOW: 'ติดต่อเรา',
    CONTACT_US_1: 'แอพพ้อยท์ ยินดีให้บริการ',
    CONTACT_US_2: 'สามารถติดต่อสาธิตการใช้งาน หรือ สอบถามเพิ่มเติม',
    // Error section
    FILL_NAME: 'กรุณากรอกชื่อ',
    FILL_LASTNAME: 'กรุณากรอกนามสกุล',
    FILL_BUSINESSNAME: 'กรุณากรอกชื่อธุรกิจ/ร้านค้า',
    INVALID_NUM: 'เบอร์โทรศัพท์ไม่ถูกต้อง',
    EXCEED_NUM: 'เบอร์โทรศัพท์ไม่สามารถมีเกิน 10 ตัว',
    UNDER_NUM: 'เบอร์โทรศัพท์ไม่สามารถมีน้อยกว่า 9 ตัว',
    INVALID_MAIL: 'อีเมลไม่ถูกต้อง',
    // AIS Bizup
    FIRST_LAST_NAME: 'ชื่อ-นามสกุล',
    CORP_NAME_AIS: 'ชื่อบริษัท',
    CONTACT_NO: 'หมายเลขโทรศัพท์ (ที่สามารถติดต่อได้)',
    CONTACT_PRIVILEGE: 'หมายเลขโทรศัพท์ (ที่รับสิทธิ์)',
    PRIVILEGE_CODE: 'โค้ดส่วนลด (กด *545*800*177# แล้วโทรออก)',
    FILL_FULL_NAME: 'กรุณากรอกชื่อและนามสกุล',
    FILL_CORPNAME: 'กรุณากรอกชื่อบริษัท',
    FILL_PRIVILEDGECODE: 'กรุณากรอกโค้ดส่วนลด',
    AAPPOINTAIS: 'AAppoint',
    APPOINTDESCRIPTION: 'ระบบจัดการนัดหมายที่ช่วยเพิ่มลูกค้า เพิ่มกำไร และลดต้นทุน',
    AAPPOINTAISPROMO: 'ลูกค้า BIZ UP รับสิทธิพิเศษ ส่วนลด จากราคาเต็ม 14,400บาท เหลือเพียง 10,700 เมื่อชำระค่าบริการแบบ 1 ปี (ไม่จำกัดจำนวนเครื่อง, ผู้ใช้งานและจำนวนนัดหมาย นอกจากนี้ยังได้รับสิทธิราคาพิเศษปีละ 10,700 บาท ตลอดการใช้งานในปีถัดๆไปอีกด้วย )(ราคาโปรโมชั่น จำกัดการใช้งานต่อ 1 สาขาเท่านั้น)'
}