export default {
    LANG: 'ไทย',
    //Header Section
    FEATURE: 'Features',
    PRICING: 'Pricing',
    SOLUTION: 'Solution',
    ABOUT_US: 'About Us',
    REQUEST_DEMO: 'Request a Demo',
    SHOP_LOGIN: 'Shop Login',
    //Footer Section
    DOWNLOAD_NOW_L1: 'Download Now',
    DOWNLOAD_NOW_L2: 'Free apps for iOS and Android',
    PRODUCT: 'Product',
    AESTHETIC: 'Aesthetic Clinic',
    MEDICAL: 'Medical Clinic',
    DENTAL: 'Dental Clinic',
    MASSAGE: 'Massage',
    CARWASH: 'Carwash',
    GARAGE: 'Garage',
    PET: 'Pet',
    BEAUTY: 'Beauty & Salon',
    JOIN_US: 'Join Us On',
    COPYRIGHT: 'Copyright',
    AAPPOINT: 'AAppoint',
    CONTACT_US: 'Contact Us',
    TERM: 'Term of use',
    PRIVACY: 'Privacy policy',
    FAQ: 'FAQs',
    //HOME Section
    APP_DESCRIPTION_L1: 'Appointment Scheduling Platform',
    APP_DESCRIPTION_L2: 'Support SMEs in terms of business operation and marketing',
    MARKET_DETAIL_L1: 'The best quality service marketplace',
    MARKET_DETAIL_L2: 'Appointment Platform can increase a loyal customer',
    MARKET_DETAIL_L3: 'Communicate effectively with customers can lead to increased sales',
    TRY_FREE: 'Try Free (14 Days)',
    NO_CREDIT_REQUIRE: 'No credit card required.',
    AAPPOINT_MARKET_L1: 'Get to know "AAppoint"',
    WHAT_IS_AAPPOINT: 'What’s AAppoint ?',
    AAPPOINT_IS: 'AAppoint is the First and number one appointment platform designed for helping business’s owner in service sector to manage their service activities by making an appointment in well prepared manner.',
    SEE_MORE: 'See More >>',
    WHY_AAPPOINT: 'Why AAppoint ?',
    WHY_AAPPOINT1: 'Retrieve existing customers to use the service by sending alert messages such as Remind customers to return for dental examination after the 6-month anniversary',
    WHY_AAPPOINT2: 'Advertising by focusing on the target audience at a lower price. Because it does not depend on the number of messages',
    WHY_AAPPOINT3: 'Customers can see the variety of services. Even though never using the service',
    WHY_AAPPOINT4: 'Better cash flow',
    WHY_AAPPOINT5: 'More people will see your shop, it will make your customers more (Rating)',
    WHY_AAPPOINT6: 'Able to plan the business because you can see the appointments of the whole shop',
    WHY_NOW: 'Why Now ?',
    GET_START: 'Get started for free (14 Days)',
    TRY_NOW: 'Try Now!',
    //FEATURE Section
    MAKE_APPOINT: 'Make an appointment',
    MAKE_APPOINT_DETAIL: 'Make an appointment easily with an online calendar that can select a date and time. Moreover, let both customers and clinics can see an appointment information at the same time and manage appointments efficiently.',
    AGENDA: 'Agenda and Appointment Detail',
    AGENDA_DETAIL: 'Customer and agent can see the appointment detail anytime and anywhere on mobile application that ensure no one forgets an appointment.',
    NOTI: 'Mobile Application Notification',
    NOTI_DETAIL: 'Send notifications for any change on the schedule including bookings, modifications and cancellations. Customers are also informed in time for changes in their appointments',
    RESCHEDULE: 'Reschedule',
    RESCHEDULE_DETAIL: 'With a mobile application, your customer can easily view your real-time calendar availability and self-reschedule appointments.',
    NOTI_SMS: 'SMS Notification',
    NOTI_SMS_DETAIL: 'Send automated SMS reminders to your customer one day before each appointment. Ensure that your customers never miss an appointment again.',
    RATING: 'Satisfaction Score',
    RATING_DETAIL: 'Customer Satisfaction Score can guarantee the quality of service, increase customers, and make your shop/clinic more popular.',
    // **delete
    MEDICAL_RECORD: 'Medical Record',
    MEDICAL_RECORD_DETAIL: 'Easy to find and record the document that explains all detail about the patient’s history.',
    ROOM_MANAGEMENT: 'Room Management',
    ROOM_MANAGEMENT_DETAIL: 'Create room for matching the time and service you provided.',
    // delete
    LEAVE: 'Leave Request',
    LEAVE_DETAIL: 'Doctor or service provider can create your available time into the calendar and share them with another collaborator.',
    BOOKING: 'Booking',
    BOOKING_DETAIL: 'Make it easy for your customers can book your services on a mobile application at any time to pick an appointment slot that is suitable for them.',
    TRAVELTIME: 'Travel Time',
    TRAVELTIME_DETAIL: 'Can check and find the best route to save time that helps them do not miss an appointment.',
    // **delete
    QUEUE: 'Queue Management',
    QUEUE_DETAIL: 'Keep your waiting lobbies empty and inform customers what their current queue status is. Help your customer and staff practice social distancing, to minimize the safety risks.',
    SHOP: 'Shop Management',
    SHOP_DETAIL: 'Set up your services and the employees which will serve the customers. Configure the system at your own will apply the business hours and breaks and let customers book, modify, or cancel appointments within specific time periods.',
    APPOINT_SUM: 'Appointment Summary',
    APPOINT_SUM_DETAIL: 'Understand your appointment traffic and your customer needs',
    // delete
    OVERVIEW_FEATURE: 'An overview of AAppoint available features.',
    // Request Demo section
    CONTACT_SALE_1: 'Contact Sales',
    CONTACT_SALE_2: 'To schedule a free demonstration via video call.',
    FIRST_NAME: 'First name',
    LAST_NAME: 'Last name',
    PHONE_NUM: 'Phone number',
    E_MAIL: 'E-mail',
    BUSINESS_NAME: 'Business name',
    PROVIDE_SERVICE: 'Which service provide to customer ?',
    SUBMIT_REQUEST: "Request a Demo",
    GREETING_1: 'Thank you for your interest in our products.',
    GREETING_2: 'Your request for a demo has been sent to our team, please look forward to hearing from us.',
    // Pricing section
    // **delete
    PAY_AS_YOU_GROW_1: 'PAY AS YOU GROW',
    PAY_AS_YOU_GROW_2: 'you’ll only pay us when you get paid and without paying a fixed monthly fee.',
    // delete
    SUBSCRIPTION_1: 'Monthly Service Fee',
    SUBSCRIPTION_2: 'pay 1200 ฿ per shop',
    NO_INSTALL_FEE: 'No installation fees',
    NO_MAINTAIN_FEE: 'No maintenance fees',
    UNLIMIT_AAPPOINT: 'Unlimited appointment',
    PRICING_PLAN: 'No complicated pricing plan',
    PRICE: 'Monthly service fee 1200 ฿ per shop',
    LIMIT: '(Up to 1,000 appointments/month)',
    // **delete
    TRANSAC_DETAIL_1: 'Free !',
    TRANSAC_DETAIL_2: 'Entrance fee 2,999 baht',
    TRANSAC_DETAIL_3: '(First 100 clinics)',
    TRANSAC_DETAIL_4: 'Sign up today for 3 months free',
    TRANSAC_DETAIL_5: '3 THB per transaction',
    // delete
    FEATURE_1: 'Make an appointment',
    FEATURE_2: 'SMS Notification',
    FEATURE_3: 'Satisfaction Score',
    FEATURE_4: 'Agenda and Appointment Detail',
    FEATURE_5: 'Reschedule',
    FEATURE_6: 'Booking',
    FEATURE_7: 'Mobile Application Notification',
    FEATURE_8: 'Leave Request',
    FEATURE_9: 'Travel Time',
    PRICE_TERM: 'Company reserves the right to change/modify Terms & Conditions without prior notice.',
    PRICE_TERM_1: 'Company reserves the right to change/',
    PRICE_TERM_2: 'modify Terms & Conditions without prior notice.',
    // Solution section
    SOLUTION_DETAIL_1: 'Solutions',
    SOLUTION_DETAIL_2: 'AAppoint do more than simply make an appointment, you can schedule an appointment as your preferences. And get more value to get customer satisfaction and brand loyalty.',
    AESTHETIC_DETAIL: 'AAppoint Platform will allow you to produce a database that contains all of the contact details of your highest value customers, and so can be used as the starting point for outreach campaigns.',
    MEDICAL_DETAIL: 'The time-saving benefits you get from using AAppoint Platform translate into financial gains. The time which would have otherwise been spent booking appointments is now spent on other revenue-generating business activities. The resources that were initially used to manage appointments may be further directed to other productive tasks.',
    DENTAL_DETAIL: 'If you’ve worked with a paper-based scheduling system, you will know that systems like that can quickly become enormously complex. Traditional systems are simply a huge waste of time, money, and paper. But AAppoint Platform gets around all these problems and lets you focus on what matters most engaging with your customer.',
    MASSAGE_DETAIL: 'The customer service staff spends most of his time handling phone calls, explaining options to customers and managing schedules, all of which can be automated. The time-saving benefits work similarly for your prospects. The individuals who want to book an appointment with you do not necessarily have to commit part of their busy routine to call you for an appointment.',
    GARAGE_DETAIL: 'AAppoint Platform can manage your staff schedules for flexible scheduling to keep your staffs happy and handled efficiently. A better way to plan and manage your business. AAppoint stores appointments for you and keeps you up-to-date so you can focus on your business, not expenses.',
    CARWASH_DETAIL: 'Regardless of the industry, you’re in; there are always customers who repeatedly no-show, cancel or try to rebook their appointments last minute. AAppoint can help you to consider your staffing and scheduling , based on the number of appointments you have.',
    PET_DETAIL: 'The most successful business owners look at their potential customers, fill their needs and what they’re willing to pay for. When you think ahead about the type of people (and their dogs) that can help you organize schedule that will work best.',
    BEAUTY_DETAIL: 'Have you ever had a scenario where two customers turned up at the same time? This is something not new for any small business, and mistakes like these happen more frequently, to avoid such errors, it is mandatory that an online appointment scheduling system is put in place, which will remove all the redundancy in the booking.',
    // About us section
    ABOUT_US_DETAIL: 'Stay connected with AAppoint worldwide',
    OUR_TEAM: 'Our Teams',
    RATAPON: 'Mr. Ratapon Narkvichian',
    YUWANUCH: 'Dr. Yuwanuch Narkvichian DDS.',
    SUWADEE: 'Dr. Suwadee Puntpanich (Ph.D)',
    JATURONG: 'Mr. Jaturong Anuvatesirikeart',
    VARISSARA: 'Ms. Varissara Bunthiam',
    THAMONWAN: 'Ms. Thamonwan Jansa',
    UBONRAT: 'Ms. Ubonrat Phonchai',
    KIATTIPHON: 'Mr. Kiattiphon Santhima',
    POWERED: 'Powered by',
    ADDRESS: 'Address :',
    TEL: 'Tel :',
    EMAIL: 'Email :',
    LINE_OA: 'LINE :',
    CORP_NAME: 'Appointment Anywhere Co., Ltd. (Head Office)',
    OFFICE_ADDR_1: 'No.25 Alma link building, Level 17, Room 222,',
    OFFICE_ADDR_2: 'Soi Chidlom Ploenchit Rd., Lumpini, Phatumwan Bangkok 10330',
    CONTACT_NOW: 'Contact Now',
    CONTACT_US_1: 'Feel free to Contact us',
    CONTACT_US_2: 'We`d love to hear your thoughts and suggestions',
    // Error section
    FILL_NAME: 'Please fill Fistname',
    FILL_LASTNAME: 'Please fill Lastname',
    FILL_BUSINESSNAME: 'Please fill business name',
    INVALID_NUM: 'Invalid Phone Number',
    EXCEED_NUM: 'Phone number can not more than 10 digits',
    UNDER_NUM: 'Phone number can not less than 9 digits',
    INVALID_MAIL: 'Invalid Email',
    // AIS Bizup
    FIRST_LAST_NAME: 'ชื่อ-นามสกุล',
    CORP_NAME_AIS: 'ชื่อบริษัท',
    CONTACT_NO: 'หมายเลขโทรศัพท์ (ที่สามารถติดต่อได้)',
    CONTACT_PRIVILEGE: 'หมายเลขโทรศัพท์ (ที่รับสิทธิ์)',
    PRIVILEGE_CODE: 'โค้ดส่วนลด (กด *545*800*177# แล้วโทรออก)',
    FILL_FULL_NAME: 'กรุณากรอกชื่อและนามสกุล',
    FILL_CORPNAME: 'กรุณากรอกชื่อบริษัท',
    FILL_PRIVILEDGECODE: 'กรุณากรอกโค้ดส่วนลด',
    AAPPOINTAIS: 'AAppoint',
    APPOINTDESCRIPTION: 'ระบบจัดการนัดหมายที่ช่วยเพิ่มลูกค้า เพิ่มกำไร และลดต้นทุน',
    AAPPOINTAISPROMO: 'ลูกค้า BIZ UP รับสิทธิพิเศษ ส่วนลด จากราคาเต็ม 14,400บาท เหลือเพียง 10,700 เมื่อชำระค่าบริการแบบ 1 ปี (ไม่จำกัดจำนวนเครื่อง, ผู้ใช้งานและจำนวนนัดหมาย นอกจากนี้ยังได้รับสิทธิราคาพิเศษปีละ 10,700 บาท ตลอดการใช้งานในปีถัดๆไปอีกด้วย )(ราคาโปรโมชั่น จำกัดการใช้งานต่อ 1 สาขาเท่านั้น)'
}