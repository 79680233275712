import Cookie from 'js-cookie'
import actions from './actions'
import langEN from '../../locale/en'
import langTH from '../../locale/th'

const initialState = {
  lang: Cookie.get('LANG') || 'TH',
  locale: {
    EN: { ...langEN },
    TH: { ...langTH },
  }
}

export default function appReducer(state = initialState, action) {
    switch (action.type) {
      case actions.SET_LANGUAGE:
        Cookie.set('LANG', action.payload.lang)
        return {
          ...state,
          lang: action.payload.lang,
        }
      default:
        return state
    }
  }